<template>
	<TokenValidationError automation-id="expired" />
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import TokenValidationError from '@/components/common/TokenValidationError';

@Component({
	name: 'ExpiredToken',
	components: {
		TokenValidationError
	}
})
export default class ExpiredToken extends Vue {}
</script>
