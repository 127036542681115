<template>
	<BContainer fluid class="maxWidth my-5 h-100 p-0">
		<BRow>
			<BCol cols="12">
				<FontAwesomeIcon
					:icon="[iconType, icon]"
					size="xl"
					:class="[iconColor, 'iconSize', 'marginAuto', 'w-100']"
				/>
			</BCol>
			<BCol cols="12" class="mt-2">
				<h1 class="text-center px-2">{{ titleLabel }}</h1>
			</BCol>
			<BCol cols="12" class="mt-1">
				<div v-if="hasBody" class="card p-1 align-middle">
					<!-- eslint-disable-next-line -->
					<p class="pb-0 p-1 m-3 content" v-html="contentLabel"></p>
				</div>
			</BCol>
			<BCol cols="auto" class="mt-3">
				<BaseButton
					:label="btnLabel"
					variant="primary"
					class="btn-reset"
					:automation-id="getAutomationId('token-error')"
					@click="clickedEvent"
				/>
			</BCol>
		</BRow>
	</BContainer>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Authentication from '@/models/Authentication';

@Component({
	name: 'TokenValidationError',
	mixins: [IdMixin],
	components: {
		BContainer,
		BRow,
		BCol,
		BaseButton,
		FontAwesomeIcon
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		},
		hasBody: {
			type: Boolean,
			default: true
		},
		button: {
			type: String,
			default: ''
		},
		icon: {
			type: String,
			default: 'alarm-clock'
		},
		iconType: {
			type: String,
			default: 'fal'
		},
		iconColor: {
			type: String,
			default: 'errorIcon'
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class TokenValidationError extends Vue {
	get titleLabel() {
		return this.title ? this.title : this.$t('title');
	}
	get contentLabel() {
		return this.content ? this.content : this.$t('content');
	}

	get btnLabel() {
		return this.button ? this.button : this.$t('button');
	}

	async clickedEvent() {
		await Authentication.refreshExpiredToken(
			this.$route.query.token,
			this.$store.state.i18n.locale
		);
	}
}
</script>
<style lang="scss" scoped>
.maxWidth {
	max-width: 700px;
	margin: 0 auto;
}
.marginAuto {
	margin: 0 auto;
	@media (max-width: 600px) {
		margin: 0 15px;
	}
}
.iconSize {
	height: 42px;
	width: 42px;
}
.errorIcon > path {
	color: $error-icon-red;
}
.content {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: 300;
}
.btn-reset {
	min-width: 180px;
}
</style>
<i18n>
{
    "en": {
        "title": "Sorry! This link has expired.",
        "content": "Links are only valid for 24 hours.<br/> Click the button below and we'll send you a new one.",
        "button": "Resend link"
    },
    "fr": {
        "title": "(FR)Sorry! This link has expired.",
        "content": "(FR)Links are only valid for 24 hours.<br/> Click the button below and we'll send you a new one.",
        "button": "(FR)Resend link"
    }
}
</i18n>
